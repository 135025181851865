// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1723572624750.949";
}
// REMIX HMR END

import { RisingSun } from 'nobak-design-system';
import nobakWallet from '../assets/Nobak-Wallet.png';
import { Button, MenuBar } from 'nobak-design-system';
import { Link } from "@remix-run/react";
export const meta = () => {
  return [{
    title: "NOBAK"
  }, {
    name: "description",
    content: "Next generation wallet for the digital realmn"
  }];
};
export default function Index() {
  return <div>
      <MenuBar component={Link}>
        <div className="flex justify-between py-[8px]">
          <div>
            <Button text="Developers" variant="basic" type="anchor" to="https://dev.nobak.net" />
          </div>
          <div>
            <Button text="Discord" icon="Discord" variant="basic" type="anchor" to="https://discord.gg/GR5cB3y2" />
            <Button text="Github" icon="Github" variant="basic" type="anchor" to="https://github.com/nobak-net" />
          </div>
        </div>
      </MenuBar>
      <RisingSun>
        <div style={{
        width: '290px'
      }}>
          <img src={nobakWallet} width={290} />
          <svg width="290" height="51" viewBox="0 0 290 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="290" height="51" fill="#080909" />
            <path d="M0 44.6414V5.34564H20.9203L39.0347 28.1053H39.0869V5.34564H55.5961V44.6414H34.6758L16.5614 21.4547H16.5092V44.6414H0Z" fill="#E6E6E7" />
            <path d="M89.7106 46C83.7595 46 78.5523 45.1331 74.0759 43.4122C69.5995 41.6784 66.128 39.2329 63.6744 36.0499C61.2209 32.8669 59.9811 29.1793 59.9811 25C59.9811 20.8207 61.2078 17.1331 63.6744 13.9501C66.128 10.7671 69.5995 8.30869 74.0759 6.5878C78.5523 4.86691 83.7595 4 89.7106 4C95.6617 4 100.869 4.86691 105.345 6.5878C109.822 8.32163 113.293 10.7671 115.747 13.9501C118.2 17.1331 119.44 20.8207 119.44 25C119.44 29.1793 118.213 32.8669 115.747 36.0499C113.293 39.2329 109.822 41.6913 105.345 43.4122C100.869 45.1331 95.6617 46 89.7106 46ZM89.7106 33.9926C92.138 33.9926 94.2653 33.6174 96.0924 32.8799C97.9326 32.1294 99.342 31.0943 100.334 29.7357C101.326 28.39 101.822 26.8115 101.822 24.9871C101.822 23.1627 101.326 21.5841 100.334 20.2384C99.342 18.8928 97.9326 17.8447 96.0924 17.1072C94.2523 16.3567 92.125 15.9945 89.7106 15.9945C87.2962 15.9945 85.1559 16.3697 83.3288 17.1072C81.4887 17.8577 80.0792 18.9057 79.0873 20.2384C78.0955 21.5841 77.5995 23.1627 77.5995 24.9871C77.5995 26.8115 78.0955 28.39 79.0873 29.7357C80.0792 31.0813 81.4887 32.1294 83.3288 32.8799C85.169 33.6303 87.2962 33.9926 89.7106 33.9926Z" fill="#E6E6E7" />
            <path d="M123.838 44.6414V5.34564H159.01C162.416 5.34564 165.326 5.75969 167.702 6.60073C170.09 7.44176 171.891 8.67097 173.118 10.2883C174.344 11.9057 174.958 13.8854 174.958 16.2144C174.958 17.5212 174.697 18.7246 174.188 19.8114C173.679 20.8983 172.935 21.8687 171.956 22.7098C170.977 23.5508 169.803 24.1978 168.406 24.6765V24.78C170.86 25.4658 172.752 26.6044 174.07 28.1959C175.389 29.7745 176.054 31.7024 176.054 33.9538C176.054 36.2052 175.454 38.1978 174.24 39.7893C173.026 41.3937 171.265 42.597 168.955 43.4122C166.645 44.2273 163.813 44.6414 160.472 44.6414H123.812H123.838ZM153.842 35.6358C154.834 35.6358 155.682 35.5065 156.374 35.2606C157.065 35.0018 157.587 34.6266 157.94 34.109C158.292 33.6044 158.462 32.9963 158.462 32.3105C158.462 31.1848 158.057 30.3179 157.248 29.7227C156.439 29.1275 155.304 28.817 153.842 28.817H141.404V35.6358H153.842ZM152.798 20.8983C153.75 20.8983 154.573 20.7689 155.277 20.5102C155.969 20.2514 156.491 19.8891 156.843 19.3974C157.196 18.9057 157.366 18.3105 157.366 17.6248C157.366 16.5767 156.974 15.7615 156.178 15.1922C155.395 14.6229 154.259 14.3512 152.798 14.3512H141.457V20.8983H152.798Z" fill="#E6E6E7" />
            <path d="M175.741 44.6414L195.121 5.34564H215.48L234.247 44.6414H216.029L213.666 38.6377H196.27L193.908 44.6414H175.741ZM199.245 29.3604H210.691L205.079 15.658H204.975L199.245 29.3604Z" fill="#E6E6E7" />
            <path d="M237.001 44.6414V5.34564H254.62V17.6765L254.724 17.7283L268.375 5.34564H290L272.277 20.8983L290 44.6414H268.923L260.166 31.0037L254.606 35.9205V44.6543H236.988L237.001 44.6414Z" fill="#E6E6E7" />
          </svg>
        </div>
      </RisingSun>
    </div>;
}
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;